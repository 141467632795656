import * as React from 'react';
import { CatalogLayout } from 'components/CatalogLayout';
import { ChangeActiveAddress } from 'modals/ChangeActiveAddress';
import { BackButton } from 'components/BackButton';

const MyAddresses = () => (
  <CatalogLayout>
    <BackButton className="text-2xl font-bold mt-7 mb-11">Выбор адреса</BackButton>
    <ChangeActiveAddress onClose={() => {}} className="p-0" createAddressClassName="justify-start p-0" hideTitle />
  </CatalogLayout>
);

export default MyAddresses;
